import {
  ActionReducerMapBuilder,
  CreateSliceOptions,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { SLICE_NAME } from "~/src/Redux/Payment/Selectors/MakePayment.selector";
import {
  DataStatus,
  IRemoteData,
  RemoteData,
} from "~/src/Lib/types/datatransfer";
import { ApiResponseError } from "~/src/Lib/types/api";
import { PaymentDetail } from "../Model/PaymentDetail.model";

export type MakePaymentListState = {
  makePaymentDetailData: IRemoteData<PaymentDetail>;
};

const initialState: MakePaymentListState = {
  makePaymentDetailData: new RemoteData(),
};

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    fetchMakePaymentList(state) {
      state.makePaymentDetailData = state.makePaymentDetailData.setLoading();
    },
    fetchMakePaymentListFail(state, action: PayloadAction<ApiResponseError>) {
      state.makePaymentDetailData = state.makePaymentDetailData.setError(
        action.payload
      );
    },
    fetchMakePaymentListSuccess(state, action: PayloadAction<PaymentDetail>) {
      state.makePaymentDetailData = state.makePaymentDetailData.setData(
        action.payload
      );
    },
    setMakePaymentStatusNotLoaded(state) {
      state.makePaymentDetailData = new RemoteData();
      state.makePaymentDetailData.status = DataStatus.NOT_LOADED;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>): void => {},
};

const makePaymentSlice = createSlice(sliceOptions);

export default makePaymentSlice.reducer;
export const {
  fetchMakePaymentList,
  fetchMakePaymentListFail,
  fetchMakePaymentListSuccess,
  setMakePaymentStatusNotLoaded,
} = makePaymentSlice.actions;
